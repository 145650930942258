
// -------------------------------------------------------------
// Variables

// Colors


$g1: #5961F9
$g2: #2AFADF
$g3: #F9FEA5
$text: #204754

// Easing
$zease: cubic-bezier(0.165, 0.84, 0.44, 1)
$easeOutBack: cubic-bezier(0.175, 0.885, 0.32, 1.275)


[data-scroll]
  opacity: 0
  will-change: transform, scale, opacity
  transform: translateY(6rem) scale(0.93)
  transition: all 1.5s $zease

[data-scroll="in"]
  opacity: 1
  transform: translateY(0) scale(1)

// -------------------------------------------------------------
// Headline Animation
.splitting
  .char
    color: transparent

    &:after
      visibility: visible
      color: $text
      opacity: 1
      transform: translateY(30%)
      transition: all .6s $easeOutBack
      transition-delay: calc(.2s + (.04s * var(--char-index) ) )

[data-scroll="in"]
  .char:after
    opacity: 1
    transform: translateY(0)

