.menu--dustu .menu__item {
	text-decoration: none;
	font-family: 'Raleway', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 40px;
	line-height: 110%;
	color: #FFFFFF;
	margin:0;
}

.menu--dustu .menu__item-name {
	font-family: 'Raleway', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 30px;
	line-height: 110%;
	color: #FFFFFF;
	overflow:hidden;
	margin-bottom:20px;
	letter-spacing: 2px;
}

.menu--dustu .menu__item-name::before,
.menu--dustu .menu__item-name::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #BAA88C;
	transform: translate3d(-100%,0,0) translate3d(-1px,0,0);
	transition: transform 0.7s;
	transition-timing-function: cubic-bezier(0.7,0,0.3,1);
}

.menu--dustu .menu__item:hover .menu__item-name::before {
	transform: translate3d(100%,0,0) translate3d(1px,0,0);
}

.menu--dustu .menu__item:hover .menu__item-name::after {
	transform: translate3d(0,0,0);
}

.menu--dustu .menu__item-name::after {
	top: calc(50% - 2px);
	height: 4px;
}

.menu--dustu .menu__item-label {
	font-size: 1em;
	margin: 0.5em 0 0 0;
	margin-top: 0.25em;
	padding: 0.5em;
	color: #565656;
	transition: color 0.7s;
	transition-timing-function: cubic-bezier(0.7,0,0.3,1);
}

.menu--dustu .menu__item:hover .menu__item-label {
	color: #fff;
}
