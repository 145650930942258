@tailwind base;
@tailwind components;
@tailwind utilities;
@import '../scss/_reset.scss';
@import '../scss/scroll.sass';
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');

$primary: #0077FF;
$dark: #000;

body{
  background:#403445;
  font-family: 'Raleway', sans-serif;
}

header{
  padding-top:65px;
  position:absolute;
  z-index:700;
  transition: all 0.35s ease-in-out;
  .logo{
    opacity:1;display: block;transition: all 0.35s ease-in-out;
    height:111px;
  }
  .logo-sticky{
    opacity:0;display: none;transition: all 0.35s ease-in-out;
  }
  .wrapper{
    max-width:1208px;
    margin:0 auto;
    ul{
      li{
        margin-right:64px;
        &:last-child{
          margin-right:0;
        }
        .meet-btn{
          border:1px solid #fff;
          border-radius: 48px;
          width:170px;
          height:44px;
          display: block;
          text-align:center;
          color:#fff;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 100%;
          letter-spacing: 0.1em;
          padding-top:17px;
        }
        a{
          font-family: 'Raleway', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 0.3em;
          color: #FFFFFF;
          text-decoration: none;
          border:none;
          span{
            margin-right:6px;
          }
        }
      }
    }
  }
}

header.sticky{
  position:fixed;
  padding:25px 0;
  background:rgba(64, 52, 69, 1);transition: all 0.35s ease-in-out;
  .logo{
    opacity:0;display: none;transition: all 0.35s ease-in-out;
  }
  .logo-sticky{
    opacity:1;display:block;height:55px;transition: all 0.35s ease-in-out;
  }
}

#content{
  .wrapper{
    max-width:1208px;
    position:relative;
    .slider-content{
      position: absolute;
      z-index:5;
      margin-top: 30%;
      width:100%;
      .text-items{
        .item{
          .item-content{
            max-width:449px;
            margin-left:133px;
            margin-top:30px;
          }
          .shadow{
            font-style: normal;
            font-weight: 700;
            font-size: 140px;
            line-height: 100%;
            color: #FFFFFF;
            opacity: 0.15;
            position: absolute;
            top:-80px;
          }
          .title{
            font-style: normal;
            font-weight: 700;
            font-size: 64px;
            line-height: 100%;
            color: #FFFFFF;
            margin-bottom:16px;
          }
          .desc{
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 150%;
            letter-spacing: -0.01em;
            color: #FFFFFF;
            max-width:373px;
            margin-bottom:27px;
          }
          .url{
            display: inline-block;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 100%;
            color: #C2B182;
          }
        }
      }
      .controls{
        position:absolute;
        right:0;
        top:80%;
        li{
          margin-bottom:18px;
        }
      }
    }
  }
}

section#slider{
  height:auto;
  overflow:hidden;
  .item{
    img{
      position:relative;
      z-index:-1;
    }
    .wrapper{
      position:absolute;
      z-index: 5;
      max-width:947px;
      left:18%;
      top:30%;
      .detail-text{
        position:relative;
        margin-top:-112px;
        padding-left:133px;
      }
      .shadow{
        font-family: 'Raleway', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 80px;
        line-height: 100%;
        color: #FFFFFF;
        opacity: 0.15;
      }
      .title{
        font-style: normal;
        font-weight: 700;
        font-size: 44px;
        line-height: 100%;
        color: #FFFFFF;
        max-width:449px;
        margin-bottom:16px;
      }
      .desc{
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        max-width:345px;
        margin-bottom:27px;
      }
      .url{
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        color: #C2B182;
        display:inline-block;
        text-decoration: none;
      }
    }
  }
  .owl-nav{
    position:absolute;
    right:18%;
    top:50%;
    display: flex;
    flex-wrap:wrap;
    width:30px;
    button{
      margin-bottom:17px;
    }
  }
}

section#about{
  background: #8F778B;
  height:850px;
  .meet-box{
    max-width:1208px;
    background:#fff;
    margin-bottom:100px;
    margin-top:-71px;
    position:relative;
    z-index:5;
    .columns{
      height:161px;
      padding:0 50px;
      input:focus ~ label, textarea:focus ~ label, input:valid ~ label, textarea:valid ~ label {
        top: -10px;
        left:20px;
        -webkit-transition: all 0.225s ease;
        transition: all 0.225s ease;
        background:#603b59;
        color:#fff;
        opacity:1;
        padding:5px 10px;
        border-radius:10px;
      }

      .styled-input {
        float: left;
        width: 100%;
        margin: 1rem 0;
        position: relative;
      }

      .styled-input label {
        padding: 1.3rem 30px 1rem 30px;
        position: absolute;
        top: 0;
        left: 0;
        -webkit-transition: all 0.25s ease;
        transition: all 0.25s ease;
        pointer-events: none;
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 100%;
        color: #605C5C;
        opacity: 0.3;
      }

      .styled-input.wide {
        width: 100%;
        max-width: 100%;
      }

      input,
      textarea {
        padding: 11px;
        padding-top:15px;
        padding-left:25px;
        padding-right:25px;
        width: 100%;
        font-size: 1rem;
        border-radius: 35px;
        border: 1px solid #8F778B;
        color:#8F778B;
      }

      input:focus,
      textarea:focus { outline: 0; }

      input:focus ~ span,
      textarea:focus ~ span {
        width: 100%;
        -webkit-transition: all 0.075s ease;
        transition: all 0.075s ease;
      }
    }
    p{
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 100%;
      color: #8F778B;
    }
    button{
      background: #603B59;
      border-radius: 35px;
      width:195px;
      height:37px;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 100%;
      color: #FFFFFF;
      padding-top:5px;
    }
  }
  .wrapper{
    max-width:1208px;
    .left{
      position:relative;
      bottom:0;
      left:0;
      width:100%;
      max-width:448px;
      img{
        box-shadow: 0px 7px 40px 3px rgba(4, 3, 3, 0.25);
        position:absolute;
      }
    }
    .right{
      max-width:624px;
      padding-top:92px;
      position:relative;
      h4{
        font-family: 'Raleway', sans-serif;
        position:absolute;
        font-style: normal;
        font-weight: 400;
        font-size: 64px;
        line-height: 100%;
        color: #FFFFFF;
        mix-blend-mode: normal;
        opacity: 0.04;
        left:-30px;
        top:0;
      }
      h3{
        font-family: 'Raleway', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 100%;
        color: #FFFFFF;
        margin-bottom:98px;
        position:relative;
        &:after{
          content:url('../img/vector-component.png');
          position:absolute;
          left:-73px;
          bottom:-55px;
        }
      }
      p{
        font-family: 'Raleway', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        color: #FFFFFF;
        margin-bottom:20px;
        overflow:hidden;
      }
      a{
        font-family: 'Raleway', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        letter-spacing: 0.1em;
        color: #C2B182;
        text-decoration: none;
      }
    }
  }
}

section#treatments{
  padding-top:200px;
  position:relative;
  z-index:5;
  height:970px;
  .wrapper{
    max-width:1208px;
    h3{
      font-family: 'Raleway', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 100%;
      color: #FFFFFF;
      margin-left:45px;
      margin-top:50px;
      position:relative;
      &:after{
        content: url('../img/vector-component.png');
        position: absolute;
        bottom: -55px;
        left: -45px;
      }
    }
    p.title{
      font-family: 'Raleway', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 85px;
      line-height: 100%;
      color: #FFFFFF;
      opacity: 0.05;
      position:absolute;
      margin-top:-50px;
    }
    .col-center{
      margin-top:-163px;
    }
    .columns{
      margin-top:100px;
      position: relative;
      .column{
        position: relative;
        .content{
          width:100%;
          position: absolute;
          bottom:41px;
          text-align:center;
          max-width:91%;
          .title{
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 100%;
            text-align: center;
            color: #FFFFFF;
            opacity:1;
            width:100%;
          }
          a{
            display: inline-block;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 100%;
            letter-spacing: 0.1em;
            color: #D6C494;
            text-decoration: none;
          }
        }
      }
      .col-iki{
        .content{
          bottom:200px;
        }
      }
    }
  }
}

section#comments{
  background:url('../img/comment-bg.png') #fff;
  background-size:100%;
  height:884px;
  padding-top:200px;
  .wrapper{
    max-width:1208px;
    h3{
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 100%;
      color: #403445;
      margin-left:45px;
      margin-top:50px;
      position:relative;
      &:after{
        content: url('../img/vector-component-2.png');
        position: absolute;
        bottom: -55px;
        left: -45px;
      }
    }
    p.title{
      font-style: normal;
      font-weight: 700;
      font-size: 85px;
      line-height: 100%;
      color: #403445;
      opacity: 0.05;
      position:absolute;
      margin-top:-50px;
    }
    .comments-carousel{
      padding:0 102px;
      margin-top:93px;
      .item{
        background: #8F778B;
        padding:40px;
        .stars{
          margin-bottom:28px;
          li{
            margin-right:4px;
            img{
              width:20px;
            }
          }
        }
        p{
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 136%;
          color: #D9DBDE;
        }
        p.name{
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 12px;
          color: #D9DBDE;
          margin-top:42px;
          position:relative;
          &:before{
            content:"";
            position:absolute;
            width:19px;
            height:1px;
            background:#D9DBDE;
            top:-25px;
          }
        }
      }
      .owl-prev{
        position: absolute;
        left:0;
        top:45%;
      }
      .owl-next{
        position:absolute;
        right:0;
        top:45%;
      }
    }
    .buttons{
      margin-top:59px;
      ul{
        li{
          margin-right:89px;
          &:last-child{
            margin-right:0;
          }
          a{
            background: #C2B182;
            width: 351px;
            height: 46px;
            display: inline-block;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            color: #FFFFFF;
            text-align:center;
            padding-top:15px;
            text-decoration: none;
            img{
              margin-right:5px;
              margin-top:-4px;
            }
          }
        }
      }
    }
  }
}

section#health-tourism{
  background:url('../img/saglik-turizmi-bg.jpg');
  background-size:cover;
  height:624px;
  padding-top:100px;
  .wrapper{
    max-width:1208px;
    h3{
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 100%;
      color: #FFFFFF;
      margin-left:45px;
      margin-top:50px;
      position:relative;
      &:after{
        content: url('../img/vector-component.png');
        position: absolute;
        bottom: -55px;
        left: -45px;
      }
    }
    p.title{
      font-style: normal;
      font-weight: 700;
      font-size: 85px;
      line-height: 100%;
      color: #FFFFFF;
      opacity: 0.05;
      position:absolute;
      margin-top:-50px;
    }
    p.desc{
      max-width:400px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      color: #FFFFFF;
      margin-left:45px;
      margin-top:70px;
    }
    a{
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 100%;
      letter-spacing: 0.1em;
      color: #C2B182;
      display: inline-block;
      margin-left:45px;
      margin-top:31px;
      text-decoration: none;
    }
  }
}

section#blog{
  background:#BAA88C;
  height:870px;
  .wrapper{
    max-width:1208px;
    h3{
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 100%;
      color: #403445;
      margin-left:45px;
      margin-top:115px;
      position:relative;
      margin-bottom:100px;
      &:after{
        content: url('../img/vector-component-2.png');
        position: absolute;
        bottom: -55px;
        left: -45px;
      }
    }
    p.title{
      font-style: normal;
      font-weight: 700;
      font-size: 85px;
      line-height: 100%;
      color: #403445;
      opacity: 0.05;
      position:absolute;
      margin-top:75px;
    }
    .blog-title{
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 140%;
      color: #FFFFFF;
      max-width:486px;
      margin-bottom:28px;
    }
    .desc{
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 150%;
      color: #FFFFFF;
      max-width:519px;
      margin-bottom:31px;
    }
    .url{
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 100%;
      color: #603B59;
    }
    .owl-nav{
      margin-top:56px;
    }
    .owl-prev{
      margin-right:38px;
    }
    .blog-cover{
      margin-top:-65px;
    }
  }
}

section#contact{
  background:#8F778B;
  padding-bottom:40px;
  padding-top:100px;
  .wrapper{
    max-width:1208px;
    padding-left:110px;
    h3{
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 100%;
      color: #FFFFFF;
      position:relative;
    }
    p.title{
      font-style: normal;
      font-weight: 700;
      font-size: 85px;
      line-height: 100%;
      color: #FFFFFF;
      opacity: 0.05;
      position:absolute;
      margin-top:-50px;
    }
    h4{
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 120%;
      letter-spacing: -0.03em;
      color: #FFFFFF;
      max-width:594px;
      margin-top:36px;
    }
    h5{
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: #C4C4C4;
      max-width:462px;
      margin-top:13px;
    }
    .social{
      li{
        margin-right:63px;
        &:last-child{
          margin-right:0;
        }
      }
    }
    .show-on-map{
      margin-top:40px;
      a{
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 100%;
        color: #FFFFFF;
        text-decoration: none;
        img{
          margin-left:12px;
        }
      }
    }
    .social-box{
      margin-top:54px;
      .b-title{
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 16px;
        display: flex;
        align-items: flex-end;
        letter-spacing: -0.01em;
        color: #FFFFFF;
      }
      .b-desc{
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-top:7px;
        max-width:280px;
        margin-bottom:28px;
      }
    }
    .working-hours-content{
      position:relative;
      max-width:439px;
      margin-top:76px;
      margin-bottom:100px;
    }
    .working-hours-content-2{
      margin-top:42px;
      .sub-desc{
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        margin-top:25px;
      }
    }
    .working-hours{
      width:100%;
      height:268px;
      backdrop-filter: blur(15px) saturate(200%);
      -webkit-backdrop-filter: blur(10px) saturate(200%);
      background-color: rgba(39, 39, 39, 0.50);
      border-radius: 12px;
      border: 1px solid rgba(231, 206, 74, 0.125);
      position:relative;
      z-index:5;
      padding:25px 42px;
      .sub-title{
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        line-height: 150%;
        letter-spacing: 0.38em;
        color: #FFFFFF;
      }
      ul{
        max-width:261px;
        margin-top:5px;
        li{
          border-bottom:1px solid #fff;
          padding-bottom:10px;
          padding-top:10px;
          &:last-child{
            border:none;
            padding-bottom:0;
          }
          p{
            span{
              margin-left:10px;
            }
          }
        }
      }
      p{
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #FFFFFF;
      }
    }
    .hw{
      position:absolute;
      background: linear-gradient(to right, rgba(169,208,113,0),rgba(255,255,255,0.4));
      width:100%;
      height:268px;
      border-radius:12px;
      top:0;
      left:0;
      right:0;
      bottom:0;
    }
    .wh{
      content:url('../img/ellipse.png');
      position:absolute;
      right:0;
      z-index:1;
      top:29%;
    }
    .emails{
      margin-top:56px;
      .b-title{
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        line-height: 150%;
        letter-spacing: 0.38em;
        color: #FFFFFF;
        margin-bottom:8px;
      }
      p{
        a{
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          letter-spacing: -0.02em;
          color: #FFFFFF;
          text-decoration: none;
        }
      }
    }
    .address{
      margin-top:22px;
      p{
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #FFFFFF;
        max-width:344px;
      }
      .b-title{
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        line-height: 150%;
        letter-spacing: 0.38em;
        color: #FFFFFF;
        margin-bottom:11px;
      }
    }
    .phones{
      margin-top:33px;
      .b-title{
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        line-height: 150%;
        letter-spacing: 0.38em;
        color: #FFFFFF;
        margin-bottom:10px;
      }
      p{
        a{
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          color: #FFFFFF;
          text-decoration: none;
        }
      }
    }
    .form-title{
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 100%;
      letter-spacing: -0.03em;
      color: #FFFFFF;
      margin-top:47px;
    }
    .form-desc{
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: #FFFFFF;
      max-width:456px;
      margin-top:22px;
      margin-bottom:50px;
    }
    form{
      label{
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        color: #FFFFFF;
        width:100%;
        display: block;
      }
      input{
        background:transparent;
        border:none;
        border-bottom:1px solid #fff;
        width:100%;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #FFFFFF;
        outline:none;
        padding:7px 0;
      }
      textarea{
        background:transparent;
        border:none;
        border-bottom:1px solid #fff;
        width:100%;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #FFFFFF;
        outline:none;
      }
      button{
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 100%;
        color: #FFFFFF;
        img{
          margin-left:13px;
        }
      }
      .label-container {
        display: block;
        position: relative;
        cursor: pointer;
        padding-left:40px;
        max-width:450px;
      }
      .label-container input {
        visibility: hidden;
        cursor: pointer;
        display: none;
      }
      .mark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: transparent;
        border:1px solid #fff;
      }

      .mark:after {
        content: "";
        position: absolute;
        display: none;
      }
      .label-container input:checked ~ .mark:after {
        display: block;
      }
      .label-container .mark:after {
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
      }
    }
  }
  .map{
    width:100%;
  }
}

footer{
  padding-top:57px;
  .wrapper{
    max-width:1208px;
    .top{
      padding-bottom:162px;
      ul{
        li{
          margin-right:47px;
          &:last-child{
            margin-right:0;
          }
          a{
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 16px;
            display: flex;
            align-items: flex-end;
            letter-spacing: -0.01em;
            color: #FFFFFF;
          }
        }
      }
    }
    .bottom{
      border-top:1px solid #fff;
      padding-top:32px;
      p{
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 240%;
        color: #FFFFFF;
      }
      ul{
        li{
          margin-right:76px;
          &:last-child{
            margin-right:0;
          }
        }
      }
    }
  }
}

section#page-banner{
  box-shadow: 0px 7px 40px 3px rgba(0, 0, 0, 0.25);
  img{
    width:100%;
  }
}

section#blog-detail-title{
  height:375px;
  .wrapper{
    max-width:1208px;
    h3{
      font-style: normal;
      font-weight: 700;
      font-size: 85px;
      line-height: 100%;
      color: #FFFFFF;
      mix-blend-mode: normal;
      opacity: 0.04;
      position:absolute;
      margin-top:-50px;
      margin-left:35px;
    }
    h4{
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 100%;
      color: #FFFFFF;
      position:relative;
      margin-left:70px;
      margin-top:100px;
      &:after{
        content: url('../img/vector-component.png');
        position: absolute;
        bottom: -55px;
        left: -70px;
      }
    }
  }
}

section#blog-detail{
  background:#fff;
  padding-bottom:186px;
  .wrapper{
    max-width:1208px;
    position:relative;
    h1{
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 100%;
      color: #C2B182;
      margin-top:92px;
      margin-left:70px;
      margin-bottom:100px;
      position:relative;
      &:after{
        content: url('../img/page-vector.png');
        position: absolute;
        bottom: -55px;
        left: -70px;
      }
    }
    h2{
      font-style: normal;
      font-weight: 700;
      font-size: 85px;
      line-height: 100%;
      color: #C2B182;
      mix-blend-mode: normal;
      opacity: 0.06;
      position:absolute;
      margin-top:-200px;
    }
    .cover{
      margin-top:-269px;
      position: relative;
      display: inline-block;
      width:100%;
    }
    .content{
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 148%;
      color: #1A1A1A;
      p{
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 148%;
        color: #1A1A1A;
        margin-bottom:30px;
      }
    }
  }
}

section#blogs{
  background:#fff;
  padding-bottom:186px;
  .wrapper{
    max-width:1208px;
    position:relative;
    h1{
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 100%;
      color: #C2B182;
      margin-top:92px;
      margin-left:70px;
      margin-bottom:100px;
      position:relative;
      &:after{
        content: url('../img/page-vector.png');
        position: absolute;
        bottom: -55px;
        left: -70px;
      }
    }
    h2{
      font-style: normal;
      font-weight: 700;
      font-size: 85px;
      line-height: 100%;
      color: #C2B182;
      mix-blend-mode: normal;
      opacity: 0.06;
      position:absolute;
      margin-top:-200px;
    }
  }
}

.media-page-title{
  background:#fff;
  height:auto !important;
  padding-top:100px;
  .wrapper{
    h1{
      font-style: normal;
      font-weight: 700;
      font-size: 85px;
      line-height: 100%;
      color: #C2B182;
      mix-blend-mode: normal;
      opacity: 0.06;
      position: absolute;
      margin-top:-40px;
      margin-left:25px;
    }
    h2{
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 100%;
      color: #C2B182;
      position: relative;
      margin-left:70px;
      &:after{
        content: url('../img/page-vector.png');
        position: absolute;
        bottom: -55px;
        left: -70px;
      }
    }
  }
}

.media-page{
  .wrapper{
    .media-gallery{
      padding-top:150px;
    }
  }
}

section#comments-page{
  background:#fff;
  padding-bottom:100px;
  .wrapper{
    max-width:1208px;
    .columns{
      padding:100px 0;
      .column-big{
        margin-top:120px;
      }
      .column{
        .item{
          background: #8F778B;
          box-shadow: 2px 4px 16px 4px rgba(64, 52, 69, 0.4);
          border-radius: 36px;
          padding:50px;
          ul{
            margin-bottom:35px;text-align: center;width:100%;display: block;
            li{
              margin-right:8px;
              display: inline-block;
              &:last-child{
                margin-right:0;
              }
            }
          }
          p{
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 135%;
            text-align: center;
            letter-spacing: 0.115em;
            color: #FFFFFF;
          }
          p.name{
            margin-top:51px;
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 135%;
            text-align: center;
            letter-spacing: 0.115em;
            color: #FFFFFF;
            opacity: 0.5;
            position:relative;
            &:before{
              content:"";
              width:32px;
              height:1px;
              background:#fff;
              position:absolute;
              top:-15px;
              left:50%;
              transform: translateX(-50%-32);
            }
          }
          .c-title{
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 120%;
            letter-spacing: -0.01em;
            text-transform: capitalize;
            color: #FFFFFF;
            margin-bottom:18px;
          }
          .c-desc{
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 120%;
            letter-spacing: -0.01em;
            text-transform: capitalize;
            color: #FFFFFF;
          }
          .forms{
            max-width:822px;
          }
          input:focus ~ label, textarea:focus ~ label, input:valid ~ label, textarea:valid ~ label {
            top: -10px;
            left:20px;
            -webkit-transition: all 0.225s ease;
            transition: all 0.225s ease;
            background:#603b59;
            color:#fff;
            opacity:1;
            padding:5px 10px;
            border-radius:10px;
          }

          .styled-input {
            float: left;
            width: 100%;
            margin: 1rem 0;
            position: relative;
          }

          .styled-input label {
            padding: 1.3rem 30px 1rem 30px;
            position: absolute;
            top: 0;
            left: 0;
            -webkit-transition: all 0.25s ease;
            transition: all 0.25s ease;
            pointer-events: none;
            font-style: normal;
            font-weight: 400;
            font-size: 11px;
            line-height: 100%;
            color: rgba(255,255,255,0.5);
          }

          .styled-input.wide {
            width: 100%;
            max-width: 100%;
          }

          input,
          textarea {
            padding: 23px;
            padding-top:23px;
            padding-left:25px;
            padding-right:25px;
            width: 100%;
            font-size: 1rem;
            border-radius: 35px;
            background:rgba(42,58,80,0.2);
            font-style: normal;
            font-weight: 700;
            line-height: 110%;
            color: rgba(255,255,255,0.5);
          }

          input:focus,
          textarea:focus { outline: 0; }

          input:focus ~ span,
          textarea:focus ~ span {
            width: 100%;
            -webkit-transition: all 0.075s ease;
            transition: all 0.075s ease;
          }
          .stars-column{
            text-align:center;margin-top:25px;
          }
          button{
            background: #C2B182;
            border-radius: 48px;
            width:279px;
            height:50px;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 110%;
            color: #FFFFFF;
            padding-top:8px;
            margin-top:15px;
          }
        }
      }
    }
  }
}

div.stars{display: inline-block;}
input.star{display: none;}
label.star {float: right;padding: 10px;font-size: 36px;color: #C2B182;transition: all .2s;opacity: 1;cursor: pointer;background:transparent !important;}
input.star:checked ~ label.star:before {content:'\f005';color: #C2B182;transition: all .25s;opacity: 1;}
input.star-5:checked ~ label.star:before {color: #C2B182;text-shadow: 0 0 0px #C2B182;}
input.star-1:checked ~ label.star:before {color: #f00;}
label.star:before{content:'\f006';font-family: FontAwesome;}

section#treatment-detail{
  background:#fff;
  padding-bottom:100px;
  .wrapper, .wrapper-2{
    max-width:995px;
    .title-content{
      background:#BAA88C;
      padding:30px 0;
      text-align:center;
      position:relative;
      z-index:5;
      margin-top:-110px;
      h1{
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 100%;
        color: #FFFFFF;
        text-align: center;
        margin-bottom:13px;
      }
      h2{
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        text-align: center;
        color: #FFFFFF;
        max-width:766px;
        display: inline-block;
        position: relative;
        overflow: hidden;
      }
    }
    .treatment-content{
      padding:50px 0;
      p{
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        color: #1A1A1A;
        margin-bottom:20px;
      }
      p.sub-title{
          font-style: normal;
          font-weight: 700;
          font-size: 22px;
          line-height: 140%;
          color: #C2B182;
          width:100%;
          display: block;
          margin-bottom:0;
      }
      ul{
        padding-left:20px;
        list-style: disc;
        margin-bottom:25px;
        li{
          text-align:left;
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 148%;
          color: #1A1A1A;
        }
      }
      p.c-title{
        font-style: normal;
        font-weight: 900;
        font-size: 18px;
        line-height: 148%;
        color: #C2B182;
        text-align:left;
      }
      h4{
        font-style: normal;
        font-weight: 900;
        font-size: 22px;
        line-height: 148%;
        color: #C2B182;
        text-align: center;
        border-bottom: 1px solid #C4C4C4;
        padding-bottom:25px;
      }
      h5{
        font-style: normal;
        font-weight: 400;
        font-size: 26px;
        line-height: 148%;
        text-align: center;
        color: #1A1A1A;
        margin-bottom:58px;
        margin-top:48px;
        max-width:1093px;
        display: inline-block;
      }
    }
  }
  .wrapper-2{
    max-width:1208px;
    text-align: center;
    .wrapper-3{
      max-width:995px;
    }
  }
  .headline{
    background:#BAA88C;
    padding:28px 0;
    .cover{
      width:153px;
      margin-right:50px;
    }
    p{
      margin-right:45px;
      font-style: normal;
      font-weight: 400;
      font-size: 26px;
      line-height: 140%;
      color: #FFFFFF;
    }
  }
  .headline-2{
    background:#8F778B;
    padding:54px 0;
    p{
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 148%;
      color: #FFFFFF;
    }
  }
  .parallax-image{
    width:100%;
  }
  .categories{
    background:#BAA88C;
    padding-top:63px;
    padding-bottom:40px;
    .wrapper{
      max-width:1208px;
      text-align:center;
      h3{
        font-style: normal;
        font-weight: 700;
        font-size: 85px;
        line-height: 100%;
        color: #FFFFFF;
        mix-blend-mode: normal;
        opacity: 0.04;
        position:absolute;
        margin-top:-50px;
      }
      h4{
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 100%;
        color: #FFFFFF;
        mix-blend-mode: normal;
        text-align:left;
        margin-bottom:51px;
        margin-left:52px;
        position:relative;
        &:after{
          content: url('../img/vector-component.png');
          position: absolute;
          bottom: -55px;
          left: -40px;
        }
      }
      img{
        display:inline-block;
      }
      svg{
        display:inline-block;
        max-width:40%;
        fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;
      }
      p{
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 100%;
        text-align: center;
        color: #FFFFFF;
        mix-blend-mode: normal;
        margin-top:17px;
        max-width:156px;
        margin:0 auto;
      }
    }
  }
  .categories-2{
    padding:0;
    padding-top:20px;
    .wrapper{
      max-width:1208px;
      text-align:center;
      img{
        display:inline-block;
      }
      svg{
        display:inline-block;
        max-width:40%;
      fill:none;stroke:#c2b182;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;
      }
      p{
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 100%;
        text-align: center;
        color: #C2B182;
        mix-blend-mode: normal;
        max-width:156px;
        margin:0 auto;
        margin-top:17px;
      }
    }
  }
}

$bg: #fff;
$text: rgb(143, 119, 139);
$gray: #4d5974;
$lightgray: #e5e5e5;
$blue: #BAA88C;

.accordion {
  margin-top:100px;
  .accordion-item {
    border-bottom: 1px solid $lightgray;
    button[aria-expanded='true'] {
      border-bottom: 1px solid $blue;
    }
  }
  .arrow{
    width: 22px;
    height: 22px;
    display: inline-block;
    position: absolute;
    top: 18px;
    right: 0;
  }
  button {
    position: relative;
    display: block;
    text-align: left;
    width: 100%;
    padding: 1em 0;
    color: $text;
    font-size: 1.15rem;
    font-weight: 400;
    border: none;
    background: none;
    outline: none;
    &:hover, &:focus {
      cursor: pointer;
      color: $blue;
      &::after {
        cursor: pointer;
        color: $blue;
        border: 1px solid $blue;
      }
    }
    .accordion-title {
      padding: 1em 1.5em 1em 0;
    }
    .icon {
      display: inline-block;
      position: absolute;
      top: 18px;
      right: 0;
      width: 22px;
      height: 22px;
      border: 1px solid;
      border-radius: 22px;
      &::before {
        display: block;
        position: absolute;
        content: '';
        top: 9px;
        left: 5px;
        width: 10px;
        height: 2px;
        background: currentColor;
      }
      &::after {
        display: block;
        position: absolute;
        content: '';
        top: 5px;
        left: 9px;
        width: 2px;
        height: 10px;
        background: currentColor;
      }
    }
  }
  button[aria-expanded='true'] {
    color: $blue;
    .icon {
      &::after {
        width: 0;
      }
    }
    + .accordion-content {
      opacity: 1;
      max-height: 800px;
      transition: all 200ms linear;
      will-change: opacity, max-height;
    }
  }
  .accordion-content {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 200ms linear, max-height 200ms linear;
    will-change: opacity, max-height;
    p {
      font-size: 1rem;
      font-weight: 400;
      margin: 2em 0;
    }
    ul{
      list-style:disc !important;
      padding-left:20px;
      li{
        margin: 1em 0;
        font-size: 1rem;
        font-weight: 400;
        list-style:disc !important;
      }
    }
  }
}

section#corporate{
  padding-top:125px;
  .wrapper{
    max-width:1208px;
    h1{
      font-style: normal;
      font-weight: 700;
      font-size: 80px;
      line-height: 100%;
      color: #FFFFFF;
      mix-blend-mode: normal;
      opacity: 0.04;
      position:absolute;
      margin-top:-50px;
      padding-left:29px;
    }
    h2{
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 100%;
      color: #FFFFFF;
      padding-left:71px;
      position:relative;
      span{
        color: #fff;
        padding-left: 71px;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        position: relative;
      }
      &:after{
        content: url('../img/vector-component.png');
        position: absolute;
        bottom: -55px;
        left: -40px;
      }
    }
    .cover{
      margin-top:40px;
      margin-right:66px;
    }
    .content{
      margin-top:123px;
      p{
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        color: #FFFFFF;
        margin-bottom:25px;
        max-width:546px;
        &:last-child{
          margin-bottom:0;
        }
      }
    }
  }
}

section#certificates{
  background:#fff;
  padding-top:196px;
  margin-top:-55px;
  padding-bottom:65px;
  .wrapper{
    max-width:1208px;
    h3{
      font-style: normal;
      font-weight: 700;
      font-size: 85px;
      line-height: 100%;
      color: #603B59;
      mix-blend-mode: normal;
      opacity: 0.04;
      position:absolute;
      margin-top:-40px;
    }
    h4{
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 100%;
      color: #603B59;
      position:relative;
      padding-left:44px;
      margin-bottom:70px;
      &:after{
        content: url('../img/vector-component-2.png');
        position: absolute;
        bottom: -55px;
        left:0;
      }
    }
  }
}

section#clinics{
  background:#8F778B;
  padding-top:146px;
  padding-bottom:170px;
  .wrapper{
    max-width:1208px;
    h3{
      font-style: normal;
      font-weight: 700;
      font-size: 85px;
      line-height: 100%;
      color: #fff;
      mix-blend-mode: normal;
      opacity: 0.04;
      position:absolute;
      margin-top:-40px;
    }
    h4{
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 100%;
      color: #fff;
      position:relative;
      padding-left:44px;
      margin-bottom:70px;
      &:after{
        content: url('../img/vector-component.png');
        position: absolute;
        bottom: -55px;
        left:0;
      }
    }
  }
}

section#docs{
  background:#8F778B;
  padding-top:146px;
  padding-bottom:170px;
  .wrapper{
    max-width:1208px;
    h3{
      font-style: normal;
      font-weight: 700;
      font-size: 85px;
      line-height: 100%;
      color: #fff;
      mix-blend-mode: normal;
      opacity: 0.04;
      position:absolute;
      margin-top:-40px;
    }
    h4{
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 100%;
      color: #fff;
      position:relative;
      padding-left:44px;
      margin-bottom:100px;
      &:after{
        content: url('../img/vector-component.png');
        position: absolute;
        bottom: -55px;
        left:0;
      }
    }
    .column{
      text-align:center;
      p{
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 108%;
        text-align: center;
        color: #FFFFFF;
        mix-blend-mode: normal;
        margin-top:31px;
      }
      img{
        display: inline-block;
      }
    }
  }
}

section#company-banner{
  background:url('../img/company-banner.jpg');
  background-size:cover;
  height:751px;
  background-repeat:no-repeat;
  .wrapper{
    max-width:1208px;
    .content{
      height:758px;
      .columns{
        .column{
          margin-bottom:75px;
          &:last-child{
            margin-bottom:0;
          }
        }
      }
    }
    .col{
      padding-left:31px;
    }
    .title{
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 108%;
      text-align: left;
      color: #FFFFFF;
      mix-blend-mode: normal;
    }
    .desc{
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 100%;
      letter-spacing: 0.1em;
      color: #FFFFFF;
      margin:12px 0;
    }
  }
}

.button_container {
   position: relative;
   width: 30px;
   height: 25px;
   z-index: 999;
   cursor: pointer;
   -webkit-transition: opacity .25s;
   transition: opacity .25s;
 }

.button_container span {
  width: 30px;
  height: 2px;
  position: absolute;
  background: #fff;
  transition: all .35s ease;
}

.top {
  top: 0px;
}

.button_container:hover .top {
  top: 2px;
}

.button_container.active:hover .top {
  top: 0px
}

.middle {
  top: 10px;
}

.bottom {
  top: 20px;
}

.button_container:hover .bottom {
  top: 18px;
}

.button_container.active:hover .bottom {
  top: 20px;
}

.button_container.active .top {
  -webkit-transform: translateY(10px) translateX(0) rotate(45deg);
  transform: translateY(10px) translateX(0) rotate(45deg);
  background: white;
}

.button_container.active .middle {
  opacity: 0;
}

.button_container.active .bottom {
  -webkit-transform: translateY(-10px) translateX(0) rotate(-45deg);
  transform: translateY(-10px) translateX(0) rotate(-45deg);
  background: white;
}

.overlay {
  position: fixed;
  background: url('../img/fullmenu-bg.jpg');
  background-repeat:no-repeat;
  background-size:cover;
  visibility: hidden;
  width: 100%;
  height: 0%;
  left: 0px;
  top: 0px;
  opacity: 0;
  overflow-y: scroll;
  -webkit-transition: opacity .35s, height .35s, visibility .35s;
  transition: opacity .35s, height .35s, visibility .35s;
  z-index: 500;
}

.overlay.open {
  height: 100%;
  opacity: 1;
  visibility: visible;
}

.overlay.open a {
  -webkit-animation: fadeInTop .5s ease forwards;
  animation: fadeInTop .5s ease forwards;
  -webkit-animation-delay: .35s;
  animation-delay: .35s;
}

.overlay.open a:nth-of-type(2) {
  -webkit-animation-delay: .4s;
  animation-delay: .4s;
}

.overlay.open a:nth-of-type(3) {
  -webkit-animation-delay: .45s;
  animation-delay: .45s;
}

.overlay.open a:nth-of-type(4) {
  -webkit-animation-delay: .50s;
  animation-delay: .50s;
}

.overlay.open a:nth-of-type(5) {
  -webkit-animation-delay: .55s;
  animation-delay: .55s;
}

.overlay.open a:nth-of-type(6) {
  -webkit-animation-delay: .60s;
  animation-delay: .60s;
}

.overlay.open a:nth-of-type(7) {
  -webkit-animation-delay: .65s;
  animation-delay: .65s;
}

.overlay.open a:nth-of-type(8) {
  -webkit-animation-delay: .70s;
  animation-delay: .70s;
}

.overlay.open a:nth-of-type(9) {
  -webkit-animation-delay: .75s;
  animation-delay: .75s;
}

.overlay .nav {
  position: relative;
  height: 70%;
  top: 60%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 50px;
  font-family: 'Lato', serif;
  font-weight: lighter;
  letter-spacing: 10px;
  text-align: center;
}

.overlay ul {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  display: inline-block;
  position: relative;
  height: 100%;
}

.overlay ul a {
  display: block;
  position: relative;
  opacity: 0;
}

.overlay ul li a {
  display: block;
  position: relative;
  text-decoration: none;
  overflow: hidden;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 110%;
  color: #FFFFFF;
  font-family: 'Raleway', sans-serif;
}

.overlay ul li a:hover:after,
.overlay ul li a:focus:after,
.overlay ul li a:active:after {
  width: 100%;
}

.overlay ul li a:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  height: 1px;
  background: #FFF;
  -webkit-transition: .35s;
  transition: .35s;
}

.blog-page{
  .wrapper{
    .blog-gallery{
      padding-top:150px;
      .sub-title{
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 115%;
        color: #C2B182;
        margin-top:20px;
        margin-bottom:15px;
      }
      .desc{
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: -0.02em;
        color: #1A1A1A;
        margin-bottom:37px;
      }
      .url{
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        letter-spacing: 0.1em;
        color: #C2B182;
        text-decoration: none;
        img{
          margin-left:9px;
        }
      }
    }
  }
}

@-webkit-keyframes fadeInTop {
  0% {
    opacity: 0;
    bottom: 20%;
  }
  100% {
    opacity: 1;
    bottom: 0;
  }
}

@keyframes fadeInTop {
  0% {
    opacity: 0;
    bottom: 20%;
  }
  100% {
    opacity: 1;
    bottom: 0;
  }
}

body.active {
  overflow: hidden;
}

@media only screen and (max-width: 1440px) {
  section#slider{
    height:auto;
    .item{
      .wrapper{
        left:8%;
      }
    }
    .owl-nav{
      right:8%;
    }
  }

  section#comments{
    background-repeat:no-repeat;
    background-size:cover;
    height:auto;
    padding:50px 0;
  }

}

@media only screen and (max-width: 1280px) {

  section#slider{
    .item{
      .wrapper{
        left:3%;
        top:40%;
        .shadow{
          font-size:70px;
        }
        .title{
          font-size:50px;
        }
        .detail-text{
          margin-top:-30px;
          padding-left:0;
        }
      }
    }
    .owl-nav{
      right:3%;
    }
  }

  section#comments{
    padding-top:180px;
  }

}

@media only screen and (max-width: 1208px) {
  header{
    .wrapper{
      max-width:90%;
    }
  }

  section#blog-detail-title{
    .wrapper{
      max-width:90%;
    }
  }

  section#blog-detail{
    .wrapper{
      max-width:90%;
    }
  }

  header{
    padding-top:40px;
    .logo{
      height:90px;
    }
  }

  .media-page-title{
    padding-top:75px;
  }

  .media-page{
    .wrapper{
      .media-gallery{
        padding-top:84px;
      }
    }
  }

  section#blog{
    .wrapper{
      max-width:90%;
    }
  }

  section#contact{
    .wrapper{
      max-width:90%;
      padding-left:0;
    }
  }

  footer{
    .wrapper{
      max-width:90%;
      .top{
        padding-bottom:100px;
        img{
          width:160px;
        }
        ul{
          li{
            margin-right:20px;
          }
        }
      }
    }
  }

  section#blogs{
    padding-bottom:50px;
    .wrapper{
      max-width:90%;
    }
  }

  .blog-page{
    .wrapper{
      .blog-gallery{
        .sub-title{
          font-size:30px;
          margin-top:15px;
          margin-bottom:5px;
        }
      }
    }
  }

  section#comments-page{
    .wrapper{
      max-width:90%;
    }
  }

  section#corporate{
    .wrapper{
      max-width:90%;
    }
  }

  section#certificates{
    .wrapper{
      max-width:90%;
    }
  }

  section#clinics{
    .wrapper{
      max-width:90%;
    }
  }

  section#company-banner{
    .wrapper{
      max-width:90%;
    }
  }

  section#docs{
    .wrapper{
      max-width:90%;
    }
  }

  section#treatment-detail{
    .categories{
      .wrapper{
        max-width:90%;
      }
    }
  }

  section#treatment-detail{
    .wrapper{
      max-width:90%;
    }
  }

  section#treatment-detail{
    .categories-2{
      .wrapper{
        max-width:90%;
      }
    }
  }

  section#treatment-detail{
    .wrapper-2{
      max-width:90%;
    }
  }

  section#about{
    .wrapper{
      max-width:90%;
    }
  }

  section#about{
    .meet-box{
      max-width:90%;
    }
  }

  section#treatments{
    .wrapper{
      max-width:90%;
    }
  }

  section#comments{
    .wrapper{
      max-width:90%;
    }
  }

  section#health-tourism{
    .wrapper{
      max-width:90%;
    }
  }

}

section#contact.contact-page{
  background:transparent;
}

@media only screen and (max-width: 1024px) {

  .accordion{
    margin-top:60px;
  }

  section#treatment-detail{
    .headline{
      p{
        font-size:22px;
      }
    }
    .wrapper-2{
      .treatment-content{
        h5{
          font-size:24px;
        }
      }
    }
  }

  section#contact{
    .wrapper{
      h3{
        font-size:30px;
      }
      p.title{
        font-size:50px;
        margin-top:-35px;
      }
    }
  }

  section#about{
    .wrapper{
      .right{
        max-width:55%;
        padding-top:25px;
        h4{
          font-size:40px;
        }
        h3{
          font-size:25px;
          margin-bottom:70px;
        }
      }
      .left{
        max-width:300px;
      }
    }
  }

  section#treatments{
    padding-top:70px;
    .wrapper{
      .columns{
        margin-top:175px;
        .column{
          .content{
            .title{
              font-size:25px;
            }
          }
        }
      }
      p.title{
        margin-top:-38px;
        font-size:50px;
      }
      h3{
        font-size:30px;
      }
    }
  }

  section#comments{
    padding-top:100px;
    .wrapper{
      p.title{
        font-size:50px;
        margin-top:-30px;
      }
      h3{
        font-size:30px;
      }
    }
  }

  section#health-tourism{
    height:auto;
    padding:70px 0;
  }

  section#slider{
    .item{
      .wrapper{
        top:35%;
      }
    }
  }

}

@media only screen and (max-width: 600px) {
  section#page-banner{
    box-shadow: none;
    overflow:hidden;
    img{
      width:200%;
      max-width:200%;
    }
  }

  header{
    padding-top:30px;
    .logo{
      height:60px;
    }
    .wrapper{
      ul{
        li{
          margin-right:23px;
        }
      }
    }
  }

  section#blog{
    height:auto;
  }

  .media-page-title{
    padding-top:40px;
    .wrapper{
      h1{
        margin-left:0;
        font-size:50px;
        margin-top:-25px;
      }
      h2{
        margin-left:0;
        font-size:30px;
        &:after{
          left:0;
        }
      }
    }
  }

  .media-page{
    .wrapper{
      .media-gallery{
        padding-top:65px;
      }
    }
  }

  footer{
    padding-top:30px;
    .wrapper{
      .top{
        display: block;
        text-align:center;
        padding-bottom:30px;
        .logo{
          display:inline-block;
        }
        ul{
          flex-wrap:wrap;
          width:100%;
          margin-top:25px;
          li{
            width:50%;
            margin-right:0;
            margin-bottom:12px;
            a{
              display: inline-block;
            }
          }
        }
      }
      .bottom{
        p{
          text-align:center;
        }
        .siradisi{
          margin-left:40%;
        }
      }
    }
  }

  header.sticky{
    .logo-sticky{
      height:30px;
    }
  }

  section#blog-detail{
    padding-bottom:0;
  }

  section#blog{
    .wrapper{
      p.title{
        font-size:50px;
        margin-top:15px;
      }
      h3{
        margin-top:40px;
        margin-left:0;
        font-size:30px;
        margin-bottom:75px;
        &:after{
          left:0;
        }
      }
      .blog-title{
        max-width:100%;
        font-size:25px;
        margin-bottom:10px;
      }
      .owl-nav{
        margin-top:30px;
      }
      .blog-cover{
        margin-top:0;
      }
    }
  }

  section#contact{
    .wrapper{
      p.title{
        font-size:50px;
      }
      h3{
        font-size:30px;
      }
      h4{
        font-size:23px;
        max-width:100%;
      }
      .form-title{
        margin-top:20px;
      }
    }
  }

  .blog-page{
    .wrapper{
      .blog-gallery{
        padding-top:65px;
        .sub-title{
          font-size:22px;
        }
        .desc{
          margin-bottom:20px;
        }
      }
    }
  }

  section#blog-detail-title{
    height:auto;
    .wrapper{
      h3{
        font-size:50px;
        margin-left:0;
        margin-top:-30px;
      }
      h4{
        font-size:30px;
        margin-left:0;
        margin-top:50px;
        &:after{
          left:0;
        }
      }
    }
  }

  section#blog-detail{
    overflow:hidden;
    margin-top:90px;
    .wrapper{
      .cover{
        margin-top:20px;
      }
      h1{
        margin-top:25px;
        margin-left:0;
        font-size:22px;
        margin-bottom:70px;
        &:after{
          left:0;
        }
      }
      h2{
        font-size:25px;
        margin-top:-150px;
      }
    }
  }

  section#comments-page{
    padding-bottom:25px;
    .wrapper{
      .columns{
        padding:65px 0;
        .column{
          .item{
            padding:30px;
          }
        }
        .column-big{
          margin-top:65px;
        }
      }
    }
  }

  section#corporate{
    padding-top:80px;
    .wrapper{
      h1{
        font-size:50px;
        padding-left:0;
      }
      h2{
        font-size:30px;
        padding-left:0;
        &:after{
          left:0;
        }
      }
      .content{
        margin-top:75px;
      }
      .cover{
        margin-right:0;
        margin-top:20px;
      }
    }
  }

  section#certificates{
    padding-top:105px;
    .wrapper{
      h3{
        font-size:50px;
      }
      h4{
        font-size:30px;
        padding-left:0;
      }
    }
  }

  section#clinics{
    padding-top:70px;
    padding-bottom:70px;
    .wrapper{
      h3{
        font-size:50px;
      }
      h4{
        font-size:30px;
        padding-left:0;
      }
    }
  }

  section#company-banner{
    height:auto;
    padding:50px 0;
    .wrapper{
      .content{
        height:auto;
        .columns{
          .column{
            margin-bottom:35px;
          }
        }
      }
    }
  }

  section#docs{
    padding-top:70px;
    padding-bottom:70px;
    .wrapper{
      h3{
        font-size:50px;
      }
      h4{
        font-size:30px;
        padding-left:0;
      }
    }
  }

  section#treatment-detail{
    .wrapper{
      .title-content{
        padding:7%;
      }
      .treatment-content{
        padding:25px 0;
      }
    }
    .categories{
      .wrapper{
        h3{
          font-size:50px;
        }
        h4{
          margin-left:0;
          font-size:30px;
          &:after{
            left:0;
          }
        }
      }
    }
  }

  section#treatment-detail{
    padding-bottom:70px;
    .headline{
      .headline-content{
        flex-wrap:wrap;
        text-align:center;
        display:block;
        .cover{
          display:inline-block;
          margin-right:0;
          margin-bottom:30px;
        }
      }
      p{
        text-align:center;
        font-size:18px;
        margin-right:0;
      }
    }
    .wrapper-2{
      .treatment-content{
        h5{
          font-size:18px;
          margin:25px 0;
        }
      }
    }
  }

  section#contact{
    .wrapper{
      .form-title{
        margin-top:0;
      }
      .working-hours-content{
        margin-top:40px;
        margin-bottom:40px;
      }
    }
  }

  section#slider{

  }

  section#about{
    height:auto;
    padding:20px 0;
    .meet-box{
      margin-top:20px;
      margin-bottom:50px;
      p{
        text-align:center;
      }
      .columns{
        height:auto;
        padding:20px 20px;
        .styled-input{
          margin:0;
        }
        text-align:center;
      }
      button{
        display:inline-block;
      }
    }
    .wrapper{
      flex-wrap:wrap;
      .left{
        max-width:100%;
        img{
          position:relative;
        }
      }
      .right{
        max-width:100%;
        h3{
          &:after{
            left:0;
          }
        }
      }
    }
  }

  section#treatments{
    height:auto;
    .wrapper{
      h3{
        margin-left:0;
        &:after{
          left:0;
        }
      }
      .columns{
        margin-top:80px;
      }
      .col-center{
        margin-top:0;
      }
    }
  }

  section#comments{
    padding-top:30px;
    .wrapper{
      h3{
        margin-left:0;
        &:after{
          left:0;
        }
      }
      .comments-carousel{
        padding:0;
      }
      .buttons{
        margin-top:20px;
        ul{
          display:block;
          width:100%;
          li{
            margin-right:0;
            margin-bottom:25px;
            &:last-child{
              margin-bottom:0;
            }
            a{
              width:100%
            }
          }
        }
      }
    }
  }

  section#health-tourism{
    padding:30px 0;
    .wrapper{
      p.title{
        font-size:50px;
      }
      h3{
        font-size:30px;
        margin-left:0;
        &:after{
          left:0;
        }
      }
      p.desc{
        margin-left:0;
      }
      a{
        margin-left:0;
      }
    }
  }

  section#slider{
    .item{
      .wrapper{
        top:48%;
        .shadow{
          font-size:25px;
        }
        .title{
          font-size:20px;
        }
        .desc{
          font-size:14px;
          margin-bottom:10px;
        }
      }
    }
  }

  header{
    padding-top:15px;
  }


}

.svg{
  fill: none;
  height:100px;
  opacity:0;
}

.svg.active{
  opacity:1;
  stroke: #fff;
  stroke-width: 1px;
  fill-opacity: 0;

  stroke-dashoffset: 1000;
  stroke-dasharray: 1000;
}

.path.active {
  animation: Html5anim 12s linear forwards;
  -webkit-animation: Html5anim 12s linear forwards;
  -moz-animation: Html5anim 12s linear forwards;
  -ms-animation: Html5anim 12s linear forwards;
  -o-animation: Html5anim 12s linear forwards;
}

@keyframes Html5anim {
  0% { stroke-dashoffset: 1000; }
  50% {
    fill-opacity: 0;
    stroke-opacity: 1;
  }
  60% { stroke-dashoffset: 0; }
  70% {
    fill-opacity: 0;
    stroke-opacity: 1;
  }
  100% {
    fill-opacity: 0;
    stroke-opacity: 1;
    stroke-dashoffset: 1;
  }
}
@-webkit-keyframes Html5anim {
  0% { stroke-dashoffset: 1000; }
  50% {
    fill-opacity: 0;
    stroke-opacity: 1;
  }
  60% { stroke-dashoffset: 0; }
  70% {
    fill-opacity: 0;
    stroke-opacity: 1;
  }
  100% {
    fill-opacity: 0;
    stroke-opacity: 1;
    stroke-dashoffset: 1;
  }
}
@-moz-keyframes Html5anim {
  0% { stroke-dashoffset: 1000; }
  50% {
    fill-opacity: 0;
    stroke-opacity: 1;
  }
  60% { stroke-dashoffset: 0; }
  70% {
    fill-opacity: 0;
    stroke-opacity: 1;
  }
  100% {
    fill-opacity: 0;
    stroke-opacity: 1;
    stroke-dashoffset: 1;
  }
}
@-o-keyframes Html5anim {
  0% { stroke-dashoffset: 1000; }
  50% {
    fill-opacity: 0;
    stroke-opacity: 1;
  }
  60% { stroke-dashoffset: 0; }
  70% {
    fill-opacity: 0;
    stroke-opacity: 1;
  }
  100% {
    fill-opacity: 0;
    stroke-opacity: 1;
    stroke-dashoffset: 1;
  }
}
@-ms-keyframes Html5anim {
  0% { stroke-dashoffset: 1000; }
  50% {
    fill-opacity: 0;
    stroke-opacity: 1;
  }
  60% { stroke-dashoffset: 0; }
  70% {
    fill-opacity: 0;
    stroke-opacity: 1;
  }
  100% {
    fill-opacity: 0;
    stroke-opacity: 1;
    stroke-dashoffset: 1;
  }
}